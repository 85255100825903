<template>
  <div class="service-category-setting">
    <PageTitle title="活動館優惠設定" btn="新增" @btnClick="openDialog('create')" />

    <FiltersContainer>
      <BaseElInput
        v-model="nameSearch"
        clearable
        placeholder="輸入活動名稱"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>

      <BaseElSelect
        v-model="isEnable"
        placeholder="狀態"
        value-key="value"
        clearable
        @clear="refresh(true)"
        @change="refresh(true)"
      >
        <BaseElSelectOption
          v-for="type in [
            { value: true, label: '進行中' },
            { value: false, label: '未上架' },
          ]"
          :key="type.value"
          :label="type.label"
          :value="type"
        />
      </BaseElSelect>
    </FiltersContainer>

    <section>
      <BaseTable
        v-loading="loading"
        :data="productCategoryList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="name" label="圖片" align="center">
          <template slot-scope="scope">
            <img
              style="margin: auto;"
              :width="`${imgSize}px`"
              :src="imgSource(scope.row.Image)"
              alt=""
            >
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="name" label="名稱" align="center" />
        <BaseElTableColumn prop="enable" label="狀態" align="center">
          <template slot-scope="scope">
            <div>
              <Tag :type="scope.row.enable ? 'action' : 'info'">
                {{
                  scope.row.enable ? '進行中' : '未上架'
                }}
              </Tag>
            </div>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="startAt" label="開始日期" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.startAt) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="endAt" label="結束日期" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.endAt) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="order" label="排序" align="center" />
        <BaseElTableColumn label="操作" fixed="right" width="110" align="center">
          <template slot-scope="scope">
            <TableEditBtnGroup
              @edit="openDialog('update'), (selectRow = scope.row), findActivity()"
              @delete=";(deleteDialog = true), (selectRow = scope.row)"
            />
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="productCategoryCount"
        @pageChange="refresh(false)"
      />
    </section>

    <image-cropper
      v-if="uploadDialog"
      :image="formData.img"
      :ratio="[1.8, 1]"
      @uploaded="getImage"
      @close="uploadDialog = false"
    />

    <el-dialog
      v-if="showDialog"
      :title="dialogTitle"
      :visible.sync="showDialog"
      :close-on-click-modal="false"
      @close="resetForm()"
    >
      <section>
        <BaseElForm
          ref="form"
          :model="formData"
          label-position="top"
          :rules="formRules"
        >
          <BaseElFormItem label="圖片" prop="avatar">
            <UploadButton
              :img="formData.avatar"
              :isAvatar="true"
              @change="loadImg"
            />
          </BaseElFormItem>
          <BaseElFormItem label="名稱" prop="name">
            <BaseElInput
              v-model="formData.name"
              placeholder="請輸入名稱"
              maxlength="50"
              show-word-limit
            />
          </BaseElFormItem>
          <BaseElFormItem label="活動期間" prop="dateTime">
            <el-date-picker
              v-model="formData.dateTime"
              editable
              type="datetimerange"
              format="yyyy-MM-dd HH:mm"
              range-separator="至"
              start-placeholder="開始時間日期"
              end-placeholder="結束時間日期"
            />
          </BaseElFormItem>
          <BaseElFormItem label="排序" prop="order">
            <BaseElInput v-model="formData.order" placeholder="請輸入順序" />
          </BaseElFormItem>

          <!-- <BaseElFormItem label="是否啟用">
            <BaseElSwitch
              v-model="formData.enable"
              active-text="是"
              inactive-text="否"
            >
            </BaseElSwitch>
          </BaseElFormItem> -->

          <BaseElFormItem label="優惠條件" prop="promoRequirements">
            <BaseElSelect
              v-model="formData.promoRequirements"
              placeholder="請選擇"
              value-key="name"
              @change="formData.req = ''"
            >
              <BaseElSelectOption
                v-for="req in Object.keys(promoReq)"
                v-show="
                  formData.isStorewideActivity === promoReqDict(req).storewide
                "
                :key="req"
                :label="promoReqDict(req).name"
                :value="promoReqDict(req)"
              />
            </BaseElSelect>
          </BaseElFormItem>

          <div v-if="formData.promoRequirements !== ''">
            <BaseElFormItem
              :label="
                formData.promoRequirements.type === 'quantity' ? '數量' : '金額'
              "
              prop="req"
            >
              <BaseElInput v-model="formData.req" placeholder="請輸入" />
            </BaseElFormItem>
          </div>

          <BaseElFormItem label="優惠內容" prop="promoContents">
            <BaseElSelect
              v-model="formData.promoContents"
              placeholder="請選擇"
              value-key="name"
              @change="
                ;(formData.contentPercent = ''), (formData.contentPrice = '')
              "
            >
              <BaseElSelectOption
                v-for="req in Object.keys(promoContent)"
                v-show="
                  formData.isStorewideActivity ===
                    promoContentDict(req).storewide
                "
                :key="req"
                :label="promoContentDict(req).name"
                :value="promoContentDict(req)"
              />
            </BaseElSelect>
          </BaseElFormItem>

          <div v-if="formData.promoContents !== ''">
            <BaseElFormItem
              v-if="formData.promoContents.type === 'percent'"
              label="打折"
              prop="contentPercent"
            >
              <BaseElInput
                v-model="formData.contentPercent"
                placeholder="請輸入比例 例: 0.9 為打9折"
              />
            </BaseElFormItem>
            <BaseElFormItem
              v-if="formData.promoContents.type === 'price'"
              label="金額"
              prop="contentPrice"
            >
              <BaseElInput
                v-model="formData.contentPrice"
                placeholder="請輸入金額"
              />
            </BaseElFormItem>
          </div>

          <BaseElFormItem
            v-if="!formData.isStorewideActivity"
            label="綁定商品"
            prop="ecommerceProducts"
          >
            <!-- <ProductSelect
              :model.sync="formData.ecommerceProducts"
              multiple
              :hideActed="true"
            /> -->
            <!-- new -->
            <BaseAdavanceSelector
              title="請選擇商品"
              :value.sync="formData.ecommerceProducts"
              :get-data-api="GetProduct"
              :get-data-count-api="GetProductCount"
              multiple
            />
          </BaseElFormItem>
        </BaseElForm>
      </section>

      <div slot="footer">
        <BaseElButton plain @click=";(showDialog = false), resetForm()">
          返回
        </BaseElButton>
        <BaseElButton type="primary" @click="dialogConfirm">
          {{
            dialogType === 'create' ? '新增' : '儲存'
          }}
        </BaseElButton>
      </div>
    </el-dialog>

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="刪除後將無法復原，確定要刪除？"
      width="40%"
      @close="deleteDialog = false"
      @delete="deleteActivity(), (deleteDialog = false)"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EmptyBlock from '@/components/EmptyBlock.vue'
import { noEmptyRules, isDigitRules, rangeRules, defaultRangeMax } from '@/validation/index'
import MixinFunc from '@/components/MixinFunc.vue'
import {
  CreateActivity,
  GetActivity,
  FindActivity,
  GetActivityCount,
  UpdateActivity,
  DeleteActivity,
} from '@/api/ecommerce/activity'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import UploadButton from '@/components/Button/UploadButton.vue'
import ImageCropper from '@/components/ImageCropper.vue'
import { promoReqConfig, promoContentConfig } from '@/config/ecommerce'
// import ProductSelect from '@/components/Search/ecommerce/ProductSelect.vue'

// Utils
import { pageStartIndex } from '@/utils/table'
import formUtils from '@/utils/form'
import { dialogTitle } from '@/utils/dialog'
import { extractList, imgSrc } from '@/utils/helper'
import BaseAdavanceSelector from '@/components/Select/BaseAdavanceSelector.vue'
import { GetProduct, GetProductCount } from '@/api/ecommerce/product'
import { reactive } from 'vue'
import { isEmpty } from 'lodash'

// import * as cssvars from '@/styles/ohbot/_variables.scss'

export default {
  name: 'EcommerceActivitySetting',
  components: {
    ImageCropper,
    UploadButton,
    DeleteDialog,
    EmptyBlock,
    // ProductSelect,
    BaseAdavanceSelector,
  },
  mixins: [MixinFunc],
  setup (props, { emit }) {
    // >> 彈窗控制
    const modal = reactive({
      productSelect: false,
    })

    return {
      modal,
      GetProduct,
      GetProductCount,
    }
  },
  computed: {
    ...mapGetters(['shop']),
    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增活動優惠',
        update: '編輯活動優惠',
      })
    },
    productsList () {
      if (this.formData.ecommerceProducts.length === 0) return []
      return extractList('id', this.formData.ecommerceProducts)
    },

    requirement () {
      const form = this.formData
      return [
        {
          name: form.promoRequirements.name,
          type: form.promoRequirements.value,
          value: form.req,
        },
      ]
    },

    content () {
      const form = this.formData
      return [
        {
          name: form.promoContents.name,
          type: form.promoContents.value,
          value:
            form.promoContents.type === 'price'
              ? parseFloat(Number(form.contentPrice).toFixed(2))
              : parseFloat(Number(form.contentPercent).toFixed(2)),
        },
      ]
    },
  },

  data: () => ({
    defaultRangeMax,
    loading: false,
    nameSearch: '',
    isEnable: '',
    uploadDialog: false,
    showDialog: false,
    deleteDialog: false,
    dialogType: '',
    promoReq: promoReqConfig,
    promoContent: promoContentConfig,

    productCategoryList: [],
    productCategoryCount: 0,

    selectRow: null,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },

    avatarChanged: false,
    imgSize: 90,

    formData: {
      img: null,
      isPublic: true,
      avatar: null,
      name: '',
      order: 100,
      id: '',
      enable: true,
      dateTime: '',
      requirementLogic: 'OR',
      isStorewideActivity: false,
      promoRequirements: '',
      req: '',
      promoContents: '',
      contentPercent: '',
      contentPrice: '',
      ecommerceProducts: [],
    },

    formRules: {
      avatar: [noEmptyRules()],
      name: [noEmptyRules()],
      dateTime: [noEmptyRules()],
      order: [noEmptyRules('請輸入排序'), isDigitRules(), rangeRules()],
      promoContents: [noEmptyRules()],
      promoRequirements: [noEmptyRules()],
      req: [noEmptyRules(), isDigitRules(), rangeRules()],
      contentPercent: [noEmptyRules(), isDigitRules(), rangeRules(0, 1)],
      contentPrice: [noEmptyRules(), isDigitRules(), rangeRules()],
    },
  }),

  async mounted () {
    await this.refresh()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val) type = 'action'
      // if (!val) type = 'danger'
      return type
    },

    promoReqDict (req) {
      return promoReqConfig[req]
    },
    promoContentDict (content) {
      return promoContentConfig[content]
    },

    storewideChange () {
      this.formData.promoRequirements = ''
      this.formData.req = ''
      this.formData.promoContents = ''
      this.formData.content = ''
    },

    imgSource (image) {
      return imgSrc(this.imgSize, image)
    },

    getImage (data) {
      this.formData.avatar = data
      this.avatarChanged = true
      this.uploadDialog = false
    },

    loadImg (img) {
      this.formData.img = img
      this.uploadDialog = true
    },

    async refresh (search = false) {
      if (search) this.tableOptions.page = 1
      this.loading = true
      await this.getActivity()
      await this.getActivityCount()
      this.loading = false
    },

    syncFormData (res) {
      const data = JSON.parse(JSON.stringify(res))
      const req = data.EcommercePromoRequirements[0]
      const content = data.EcommercePromoContents[0]
      this.formData.dateTime = [data.startAt, data.endAt]
      this.formData.avatar = data.Image
      this.formData.name = data.name
      this.formData.enable = data.enable
      this.formData.order = data.order
      this.formData.isStorewideActivity = data.isStorewideActivity
      this.formData.ecommerceProducts = data.EcommerceProducts
      this.formData.promoRequirements = this.promoReqDict(req.type)
      this.formData.promoContents = this.promoContentDict(content.type)
      this.formData.req = req.value
      const contentType = this.promoContentDict(content.type).type
      if (contentType === 'percent') { this.formData.contentPercent = content.value } else if (contentType === 'price') { this.formData.contentPrice = content.value }
    },

    //= > 檢查表單輸入驗證
    async checkForm () {
      return await formUtils.checkForm(this.$refs.form)
    },

    //= > 重置表單
    resetForm () {
      formUtils.resetForm(this.$refs.form)
      this.formData = {
        img: null,
        isPublic: true,
        avatar: null,
        name: '',
        order: 100,
        id: '',
        enable: true,
        dateTime: '',
        requirementLogic: 'OR',
        isStorewideActivity: false,
        promoRequirements: '',
        req: '',
        promoContents: '',
        contentPercent: '',
        contentPrice: '',
        ecommerceProducts: [],
      }
    },

    async dialogConfirm () {
      if (!(await this.checkForm())) return
      const type = this.dialogType
      if (type === 'create') this.createActivity()
      if (type === 'update') this.updateActivity()
      this.resetForm()
      this.showDialog = false
    },

    //= > 取得優惠活動
    async getActivity () {
      this.loading = true
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      const [res, error] = await GetActivity({
        shopId: this.shop,
        start: startIndex,
        limit,
        isStorewideActivity: false,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
        enable: this.isEnable === '' ? undefined : this.isEnable.value,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryList = res
    },

    //= > 創建優惠活動
    async createActivity () {
      this.loading = true
      const [res, error] = await CreateActivity({
        shopId: this.shop,
        name: this.formData.name,
        order: this.formData.order,
        startAt: this.formData.dateTime[0],
        endAt: this.formData.dateTime[1],
        image: this.formData.avatar ? this.formData.avatar.id : undefined,
        requirementLogic: this.formData.requirementLogic,
        isStorewideActivity: this.formData.isStorewideActivity,
        promoRequirements: this.requirement,
        promoContents: this.content,
        ecommerceProducts: this.formData.isStorewideActivity
          ? undefined
          : this.productsList,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('新增成功')
    },

    async findActivity () {
      const [res, error] = await FindActivity({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (error) return this.$message.error(error)
      console.log(res)
      // this.formData = res
      this.syncFormData(res)
      // this.formData.services = res.AppointmentServices.filter(item => ! item.isRemove)
    },

    //= > 刪除優惠活動
    async deleteActivity () {
      this.loading = true
      const [res, error] = await DeleteActivity({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('刪除成功')
    },
    //= > 更新優惠活動
    async updateActivity () {
      this.loading = true
      const [res, error] = await UpdateActivity({
        shopId: this.shop,
        id: this.selectRow.id,
        name: this.formData.name,
        order: this.formData.order,
        startAt: this.formData.dateTime[0],
        endAt: this.formData.dateTime[1],
        image: this.formData.avatar ? this.formData.avatar.id : undefined,
        requirementLogic: this.formData.requirementLogic,
        isStorewideActivity: this.formData.isStorewideActivity,
        promoRequirements: this.requirement,
        promoContents: this.content,
        ecommerceProducts: this.formData.isStorewideActivity
          ? undefined
          : this.productsList,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      console.log(res)
      await this.refresh()
      this.$message.success('更新成功')
    },

    //= > 取得服務人員總數
    async getActivityCount () {
      this.loading = true
      const [res, error] = await GetActivityCount({
        shopId: this.shop,
        name: this.nameSearch === '' ? undefined : this.nameSearch,
      })
      this.loading = false
      if (error) return this.$message.error(error)
      this.productCategoryCount = res.count
    },

    async openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },
  },
}
</script>

<style>
/* .el-date-range-picker .el-input {
  width: 100% !important;
}
.el-date-range-picker .el-picker-panel__footer {
  display: flex;
  justify-content: space-between;
  padding: 9px 25px;
} */
</style>

<style scoped lang="postcss">
::v-deep .el-date-editor{
  @apply w-full;
}
.select-popper {
  @apply hidden;
}

</style>
